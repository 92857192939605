




































import {Component, Vue} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {emptyPaginatedList, FilteredQueryRequest, IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {Action, Getter} from "vuex-class";
import Check from "@/components/helpers/Check.vue";
import Icon from "@/components/helpers/Icon.vue";
import UserRole from "@/components/helpers/UserRole.vue";
import {Client, ClientApi} from "@/api/v1/ClientApi";
import {TimestampCache} from "@/store/cache";
import {Guid} from "guid-typescript";
import {UserIdentity} from "@/store/types";
import {Impersonation} from "@/store/identity";

@Component({components: {DataTable, UserRole, Check, Icon}})
export default class ClientTable extends Vue {
    dataProvider: Promise<IPaginatedList<Client>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, "id");

    @Getter tstpCache!: TimestampCache;
    @Getter getImpersonation!: Impersonation;
    @Getter getIdentity!: UserIdentity;
    @Action updateImpersonation!: Function;
    @Action updateClientId!: Function;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: false},
            {label: this.$t("email-address"), key: "email", sortable: false},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    refresh() {
        this.dataProvider = ClientApi.find(this.query);
    }

    async impersonate(clientId: Guid) {
        if (this.canImpersonate(clientId)) {
            await this.updateImpersonation(true);
            await this.updateClientId(clientId);
        }
    }

    canImpersonate(clientId: Guid) {
        let currentClientId = this.getIdentity.clientId;
        if (this.getImpersonation.active) {
            currentClientId = this.getImpersonation.actualClientId ?? Guid.createEmpty();
        }

        return currentClientId?.toString() !== clientId?.toString() && !this.getImpersonation.active;
    }
}
