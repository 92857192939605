











import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import Tabs from "@/components/Tabs.vue";
import {ITab} from "@/api/IRouterTab";

@Component({
    components: {Tabs},
    beforeRouteUpdate(to, from, next) {
        //@ts-ignore
        this.lastRoute = from;
        next();
    }
})
export default class RouterTabs extends Vue {
    @Prop({type: Boolean, default: false}) readonly vertical!: boolean;
    @Prop({type: Boolean, default: false}) readonly lazy!: boolean;
    @Prop({type: Array, default: () => []}) readonly tabs!: ITab[];

    lastRoute: Route | null = null;

    closeModal() {
        const getReturnLocation = this.$route.meta["getReturnLocation"];
        const underlayName = this.$route.meta["underlayName"];

        if (this.lastRoute && !this.$route.query["noReturn"]) {
            this.$router.go(-1);
        } else if (getReturnLocation) {
            this.$router.push(getReturnLocation(this.$route));
        } else if (underlayName) {
            this.$router.push({name: underlayName});
        }
    }
}
