


















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class UserRole extends Vue {
    @Prop({type: Number, required: true}) readonly role!: number;
}
