





































import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {
    emptyPaginatedList,
    FilteredQueryRequest,
    IFilter,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {getRequest} from "@/api/HttpMethods";
import Check from "@/components/helpers/Check.vue";

@Component({
    components: {Check, DataTable}
})
export default class SelectionTable extends Vue {
    @Model("update:selected", {type: Array, default: () => []}) readonly selected!: any[];

    @Prop({type: String}) readonly title!: string;
    @Prop({type: Boolean, default: false}) readonly autoSelect!: boolean;
    @Prop({type: String, required: true}) readonly api!: string;
    @Prop({type: String, default: "multi"}) readonly mode!: string;
    @Prop({type: String, default: "name"}) readonly initialOrderBy!: string;
    @Prop({type: Boolean, default: true}) readonly showSearch!: string;
    @Prop({type: Boolean, default: true}) readonly selectable!: string;
    @Prop({type: Array, default: () => []}) readonly filters!: IFilter[];
    @Prop({type: Array, default: () => [
            {
                label: "Name",
                key: "name",
                sortable: true
            },
            {
                label: "",
                key: "rowActions",
                sortable: false
            }
        ]}) readonly columns!: ITableColumn[];

    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, this.initialOrderBy);
    dataProvider: Promise<IPaginatedList<any>> = Promise.resolve(emptyPaginatedList());
    selections: any[] = this.selected;
    noResults = true;

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("api")
    @Watch("filters", {deep: true})
    refresh() {
        this.query.filters = this.filters;
        this.dataProvider = getRequest(this.api, this.query);
        this.dataProvider.then(data => {
            if (data.totalCount === 0) {
                this.noResults = true;
            } else {
                this.noResults = false;
            }
        });
    }

    select(selected: any) {
        this.selections = selected;
        this.$emit("update:selected", selected);
    }
}
