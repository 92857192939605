











































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {FeatureApi, IFeatureObject, IFeatureStatus} from "@/api/v1/FeatureApi";
import Icon from "@/components/helpers/Icon.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";

@Component({components: {Icon}})
export default class ManageFeatureFlags extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    globalFlags: {
        [name: string]: IFeatureObject;
    } = {};
    clientFlags: {
        [name: string]: IFeatureStatus;
    } = {};

    enabledChecked(key: string): boolean {
        return this.clientFlags[key]?.enabled ?? (this.globalFlags[key]?.status.enabled ?? false);
    }

    forceChecked(key: string): boolean {
        return this.clientFlags[key]?.force ?? (this.globalFlags[key]?.status.force ?? false);
    }

    refresh() {
        this.$store.dispatch("retrieveFeatures", this.clientId);

        FeatureApi.get(this.clientId)
            .then(value => {
                this.globalFlags = value.global;
                this.clientFlags = value.client;
            })
            .catch(err => {
                this.$bvToast.toast("Failed to load", ErrorToastSettings);

                throw err;
            });
    }

    save() {
        FeatureApi.set(this.clientId, this.clientFlags)
            .then(() => {
                this.refresh();
                this.$bvToast.toast("Successfully updated", SuccessToastSettings);
            })
            .catch(err => {
                this.$bvToast.toast("Failed to update", ErrorToastSettings);

                throw err;
            });
    }

    forceDisable(featureKey: string): boolean {
        const globalDisabled = !this.globalFlags[featureKey]?.status.enabled ?? true;
        const forced = this.globalFlags[featureKey]?.status.force ?? true;
        return globalDisabled && forced;
    }

    defer(featureKey: string): boolean {
        const clientFeature = this.clientFlags[featureKey];
        if (clientFeature) {
            return clientFeature.enabled === null;
        } else {
            return true;
        }
    }

    setUseDefault(featureKey: string, value: boolean) {
        let clientFlag = this.clientFlags[featureKey];
        const globalFlag = this.globalFlags[featureKey];
        if (globalFlag) {
            if (clientFlag === undefined) {
                clientFlag = {
                    enabled: globalFlag.status.enabled,
                    force: globalFlag.status.force
                };
                this.$set(this.clientFlags, featureKey, clientFlag);
            } else {
                clientFlag.enabled = value ? null : globalFlag.status.enabled;
                this.$set(this.clientFlags, featureKey, clientFlag);
            }
        }
    }

    setEnable(featureKey: string, value: boolean) {
        let clientFlag = this.clientFlags[featureKey];

        if (clientFlag === undefined) {
            clientFlag = {
                enabled: value,
                force: false
            };
            this.$set(this.clientFlags, featureKey, clientFlag);
        } else {
            clientFlag.enabled = value;
            this.$set(this.clientFlags, featureKey, clientFlag);
        }
    }

    setForce(featureKey: string, value: boolean) {
        let clientFlag = this.clientFlags[featureKey];

        if (clientFlag === undefined) {
            clientFlag = {
                enabled: false,
                force: value
            };
            this.$set(this.clientFlags, featureKey, clientFlag);
        } else {
            clientFlag.force = value;
            this.$set(this.clientFlags, featureKey, clientFlag);
        }
    }

    created() {
        this.refresh();
    }
}
