







import {Component} from "vue-property-decorator";
import {ITab} from "@/api/IRouterTab";
import RouterTabs from "@/components/RouterTabs.vue";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {RouterTabs}
})
export default class Admin extends Helper {

    get tabs(): ITab[] {
        const tabs: ITab[] = [];
        if (this.permissionCheck("client:read")) {
            tabs.push({
                title: "Clients",
                to: "clients",
                subRoutes: ["features"]
            });
        }
        if (this.permissionCheck("shareable-template:update")) {
            tabs.push({
                title: "Templates",
                to: "adminTemplates",
                subRoutes: ["manageTemplateAccess"]
            });
        }
        return tabs;
    }

    created() {
        if (this.$route.name === "admin") {
            if (this.permissionCheck("client:read")) {
                this.$router.push({name: "clients"});
            }
            else if (this.permissionCheck("shareable-template:update")) {
                this.$router.push({name: "adminTemplates"});
            }
        }
    }
}
