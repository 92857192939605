








































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    FilterOperand,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Template, TemplateApi, TemplateType} from "@/api/v1/TemplateApi";
import {TimestampCache} from "@/store/cache";
import {Getter} from "vuex-class";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Icon from "@/components/helpers/Icon.vue";
import Modal from "@/components/Modal.vue";
import {openBlobInNewTab} from "@/api/v1/ParcelShippingApi";

@Component({
    components: {UpdateIcon, DeleteIcon, Icon, DataTable, Modal}
})
export default class AdminTemplateTable extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    dataProvider: Promise<IPaginatedList<Template>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    Type: typeof TemplateType = TemplateType;

    labelTemplateImage: string | null = null;

    @Getter tstpCache!: TimestampCache;

    get columns(): ITableColumn[] {
        return [
            {label: "Name", key: "name", sortable: true},
            {label: "Type", key: "type", sortable: false},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.templates")
    refresh() {
        this.query.filters = [
            {
                name: "shareable",
                op: FilterOperand.Equals,
                value: true
            }
        ];

        this.dataProvider = TemplateApi.find(this.clientId, this.query);
    }

    create() {
        this.$router.push({name: "adminCreateTemplate", params: {clientId: this.clientId.toString()}});
    }

    deleteTemplate(id: Guid) {
        TemplateApi.delete(this.clientId, id)
            .then(() => {
                this.$bvToast.toast(this.$t("template-deleted").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-template").toString(), ErrorToastSettings);

                throw err;
            });
    }

    async previewTemplate(id: Guid, type: TemplateType) {
        try {
            if (type === TemplateType.OrderSheet) {
                const data = await TemplateApi.previewPdf(this.clientId, id);
                openBlobInNewTab(data);
            } else if (type === TemplateType.AddressLabel) {
                const data = await TemplateApi.previewImage(this.clientId, id);
                this.labelTemplateImage = data;
            } else if (type === TemplateType.OrderImportedEmail || type === TemplateType.DispatchEmail) {
                await TemplateApi.previewEmail(this.clientId, id);
                this.$bvToast.toast("Preview email sent", SuccessToastSettings);
            }
        } catch (err) {
            this.$bvToast.toast("Failed to preview template", ErrorToastSettings);
            throw err;
        }
    }
}
