

















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    IFilteredQueryRequest,
    IPaginatedList,
    IQueryRequest,
    QueryRequest
} from "@/api/Utils";
import {emptyTemplate, Template, TemplateApi, TemplateType} from "@/api/v1/TemplateApi";
import {TimestampCache} from "@/store/cache";
import {Getter} from "vuex-class";
import Icon from "@/components/helpers/Icon.vue";
import {Client} from "@/api/v1/ClientApi";
import SelectionTable from "@/components/SelectionTable.vue";
import Modal from "@/components/Modal.vue";
import {UserIdentity} from "@/store/types";
import {Impersonation} from "@/store/identity";

@Component({
    components: {Icon, DataTable, Modal, SelectionTable}
})
export default class ManageTemplateAccess extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly templateId!: Guid;

    template: Template = emptyTemplate(TemplateType.OrderSheet);
    dataProvider: Promise<IPaginatedList<Client>> = Promise.resolve(emptyPaginatedList());
    query: IQueryRequest = new QueryRequest(1, 10, "id");
    selectedClient: Client[] = [];
    showModal = false;

    @Getter tstpCache!: TimestampCache;
    @Getter getIdentity!: UserIdentity;
    @Getter getImpersonation!: Impersonation;

    canRevokeAccess(client: Client) {
        return this.userClientId != client.id;
    }

    get userClientId() {
        let currentClientId = this.getIdentity.clientId;
        if (this.getImpersonation.active) {
            currentClientId = this.getImpersonation.actualClientId ?? Guid.createEmpty();
        }

        return currentClientId;
    }

    get columns(): ITableColumn[] {
        return [
            {label: "Name", key: "name", sortable: false},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.templates")
    refresh() {
        this.dataProvider = TemplateApi.findClientsWithAccess(this.clientId, this.templateId, this.query);
    }

    closeModal() {
        this.showModal = false;
        this.selectedClient = [];
    }

    assignToAll() {
        TemplateApi.grantAccessToAll(this.clientId, this.templateId)
            .then(() => {
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast("Failed to assign template", ErrorToastSettings);

                throw err;
            });
    }

    grantAccess() {
        if (this.selectedClient.length === 1) {
            TemplateApi.grantAccess(this.clientId, this.templateId, this.selectedClient[0].id)
                .then(() => {
                    this.closeModal();
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast("Failed to grant client access", ErrorToastSettings);

                    throw err;
                });
        }
    }

    revokeAccess(otherClientId: Guid) {
        TemplateApi.revokeAccess(this.clientId, this.templateId, otherClientId)
            .then(() => {
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast("Failed to revoke template access", ErrorToastSettings);

                throw err;
            });
    }

    created() {
        TemplateApi.findById(this.clientId, this.templateId)
            .then(data => {
                this.template = data;

                if (!this.template.shareable)
                    this.$router.push("admin");
            })
            .catch(err => {
                this.$bvToast.toast("Failed to retrieve template", ErrorToastSettings);

                throw err;
            });
    }
}
